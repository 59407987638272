import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TableItemWrapper } from './CardTableItem.elements'

function CardTableItem(props) {
  // Destructure
  const { children } = props

  return (
    <TableItemWrapper>
      {children && children}
    </TableItemWrapper>
  )
}

// Default Props
CardTableItem.defaultProps = {
  children: '',
}

// Proptypes Validation
CardTableItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default CardTableItem
