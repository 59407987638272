// * Accounts
export const ACCOUNT_STATUS_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Inactive', value: 0 },
  { label: 'Active', value: 1 },
  { label: 'On Hold', value: 2 },
  { label: 'Dormant', value: 3 },
]
export const ACCOUNT_TYPE_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Individual', value: 1 },
  { label: 'Entity', value: 2 },
]
export const ACCOUNT_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
// * Recipients
export const RECIPIENT_TYPE_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Individual', value: 1 },
  { label: 'Entity', value: 2 },
]
export const RECIPIENT_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
// * VASPS
export const VASP_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]

// * Travel Rule
export const TRAVELRULE_SORT = [
  { label: 'Amount (Highest)', value: '-amount' },
  { label: 'Amount (Lowest)', value: 'amount' },
  { label: 'Date (Newest)', value: '-last_modified' },
  { label: 'Date (Oldest)', value: 'last_modified' },
]
export const TRAVELRULE_STATUS_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Rejected', value: -3 },
  { label: 'Cancelled', value: -2 },
  { label: 'Confirmed', value: -1 },
  { label: 'Initiated', value: 0 },
]
export const TRAVELRULE_ASSET_FILTER = [
  { label: 'All', value: undefined },
  { value: 'BTC', label: 'Bitcoin' },
  { value: 'ETH', label: 'Ethereum' },
  { value: 'BCH', label: 'Bitcoin Cash' },
  { value: 'LTC', label: 'Litecoin' },
]

//  * Address
export const ADDRESS_SORT_DATE_FILTER = [
  { label: 'Date (Newest)', value: '-modify_tz' },
  { label: 'Date (Oldest)', value: 'modify_tz' },
  { label: 'Risk Score (Highest)', value: '-risk_ratio' },
  { label: 'Risk Score (Lowest)', value: 'risk_ratio' },
]
export const REPORT_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-create_tz' },
  { label: 'Oldest', value: 'create_tz' },
]
