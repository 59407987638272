// import { publicAxios } from 'common/utils/axios'
import { authAxios } from 'common/utils/axios'

// Endpoint
import { AUTH_ENDPOINTS, BASE_URL, DEMO_URL } from 'common/constants/apiUrls'
import QueryString from 'qs'
import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
export const newAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const Login = async ({ username, password }) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Accept: 'application/x-www-form-urlencoded',
    },
    data: QueryString.stringify({
      username,
      password,
    }),
    url: BASE_URL + AUTH_ENDPOINTS.LOGIN,
  }
  const response = await axios(options)

  const { data } = response
  // Adding expiry date to credentials
  const expDate = new Date()
  expDate.setHours(expDate.getHours() + 8)

  const dataWithExpDate = {
    ...data,
    expiry: expDate,
  }
  return dataWithExpDate
}

export const UpdatePassword = async ({ user_id, new_password }) => {
  const response = await authAxios.put(AUTH_ENDPOINTS.RESET_PASSWORD, {
    user_id,
    new_password,
  })

  const { data } = response

  return data
}

// !FIND A BETTER WAY AROUND IT
export const GetUser = async ({ user_id }) => {
  const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
  const customAuthAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const response = await customAuthAxios.get(AUTH_ENDPOINTS.GET_USER + user_id)

  const { data } = response

  return data
}
