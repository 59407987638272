/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import ChangeIcon from 'assets/images/change'

// Hooks
import { useUpdateTransfer } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  Wrapper,
  LineBreak,
  Container,
  ColWrapper,
  RowWrapper,
  FlowWrapper,
  FlowContainer,
  FlowDetailsWrapper,
  TransferDetailsWrapper,
  ButtonWrapper,
} from './TravelRuleTableItemDetails.elements'

// Components
import { VerticalDotProgress, LoaderWrapper, LoaderCircle, Button, ButtonSplitSelect } from 'views/components'

// Layouts
import { TravelRuleTableItemSubHeader } from 'views/layouts'
import moment from 'moment'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TravelRuleTableItemDetails(props) {
  // Destructure
  const { transfer_status, transfer_details, isLoading, actions, table_data } = props
  const {
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setToBeUpdatedRecipient,
    setIsPageTableUpdated,
    showAlert,
  } = actions
  const { direction_id } = table_data

  // hooks
  const [page, setPage] = useState(0)
  const [formattedFlow, setFormattedFlow] = useState(null)
  const { updateTransfer, transferUpdateData, isTransferUpdateSuccess } = useUpdateTransfer()

  // Functions
  const handleUpdateRecipient = () => {
    setToBeUpdatedRecipient(transfer_details.recipient)
    if (transfer_details.recipient.recipient_type_id === 1) return setIsIndividualRecipientDrawerOpen(true)
    if (transfer_details.recipient.recipient_type_id === 2) return setIsEntityRecipientDrawerOpen(true)
  }

  // UseEffects
  useEffect(() => {
    if (transferUpdateData && isTransferUpdateSuccess) setIsPageTableUpdated(true)
    if (transferUpdateData && !isTransferUpdateSuccess) {
      showAlert({ type: 'error', message: 'An error occured in updating record' })
    }
  }, [transferUpdateData, isTransferUpdateSuccess])
  useEffect(() => {
    if (!isLoading && transfer_status) {
      const steps = []
      transfer_status.transfer_steps.forEach((item) => {
        steps.push({ leftLabel: item.date, rightLabel: item.label })
      })
      setFormattedFlow(steps)
    }
  }, [transfer_status, isLoading])
  return (
    <Wrapper>
      <Container>
        {isLoading && (
          <LoaderWrapper>
            <LoaderCircle />
          </LoaderWrapper>
        )}
        {!isLoading && transfer_status && transfer_details && (
          <>
            {direction_id === 1 ? (
              <ButtonWrapper>
                <Button
                  size="small"
                  color="tertiary"
                  onClick={() => {
                    updateTransfer({ transfer_id: transfer_status.transfer_id, values: { transfer_status_id: -3 } })
                  }}
                >
                  Reject
                </Button>
                <ButtonSplitSelect
                  value="Confirm"
                  onClick={() => {
                    updateTransfer({ transfer_id: transfer_status.transfer_id, values: { transfer_status_id: -1 } })
                  }}
                  options={[{ value: 'Change', onClick: handleUpdateRecipient, endIcon: <ChangeIcon /> }]}
                />
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <Button
                  size="small"
                  color="tertiary"
                  onClick={() => {
                    updateTransfer({ transfer_id: transfer_status.transfer_id, values: { transfer_status_id: -2 } })
                  }}
                >
                  Cancel
                </Button>
              </ButtonWrapper>
            )}

            <TravelRuleTableItemSubHeader page={page} setPage={setPage} />
            {page === 0 && transfer_status && formattedFlow !== null && (
              <FlowWrapper>
                <FlowContainer>
                  <FlowDetailsWrapper>
                    <RowWrapper>
                      <Text>Date Initiated:</Text>
                      <Text>{moment(transfer_status.created).format('YYYY-MM-DD')}</Text>
                    </RowWrapper>
                    <RowWrapper>
                      <Text>Transfer Number:</Text>
                      <Text>{transfer_status.transfer_id}</Text>
                    </RowWrapper>
                    {/* <RowWrapper>
                      <Text>Transfer set by:</Text>
                      <Text>{transfer_status.created_by_desc}</Text>
                    </RowWrapper> */}
                    <RowWrapper>
                      <Text>Amount:</Text>
                      <Text>{transfer_status.amount || 'NA'}</Text>
                    </RowWrapper>
                    <RowWrapper>
                      <Text>Amount USD:</Text>
                      <Text>{transfer_status.amount_usd || 'NA'}</Text>
                    </RowWrapper>
                  </FlowDetailsWrapper>
                </FlowContainer>
                <FlowContainer>
                  <VerticalDotProgress headers={formattedFlow} />
                </FlowContainer>
              </FlowWrapper>
            )}

            {page === 1 && (
              <TransferDetailsWrapper>
                <RowWrapper>
                  <ColWrapper>
                    <>
                      <Title>Originator</Title>
                      <RowWrapper>
                        <Text>Name: </Text>
                        <Text>{transfer_details.account.display_name}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Account ID: </Text>
                        <Text>{transfer_details.account.external_id}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Street Address: </Text>
                        <Text>{transfer_details.account.address_street}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>City: </Text>
                        <Text>{transfer_details.account.address_city}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Region: </Text>
                        <Text>{transfer_details.account.address_region}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Postcode: </Text>
                        <Text>{transfer_details.account.address_postcode}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Country of Domicile: </Text>
                        <Text>{transfer_details.account.address_country_desc}</Text>
                      </RowWrapper>
                    </>
                    <LineBreak />
                    <>
                      <Title>Originator VASP</Title>
                      <RowWrapper>
                        <Text>VASP Name: </Text>
                        <Text>{transfer_details.originator_vasp.vasp_name_business}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Legal Name: </Text>
                        <Text>{transfer_details.originator_vasp.vasp_name_legal}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Website: </Text>
                        <Text>{transfer_details.originator_vasp.vasp_website}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Email: </Text>
                        <Text>{transfer_details.originator_vasp.vasp_email}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Country of Domicile: </Text>
                        <Text>{transfer_details.originator_vasp.address_country_desc}</Text>
                      </RowWrapper>
                    </>
                  </ColWrapper>

                  <ColWrapper>
                    <>
                      <Title>Recipient</Title>
                      <RowWrapper>
                        <Text>Name: </Text>
                        <Text>{transfer_details.recipient.display_name}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Account ID: </Text>
                        <Text>{transfer_details.recipient.external_id}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Street Address: </Text>
                        <Text>{transfer_details.recipient.address_street}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>City: </Text>
                        <Text>{transfer_details.recipient.address_city}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Region: </Text>
                        <Text>{transfer_details.recipient.address_region}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Postcode: </Text>
                        <Text>{transfer_details.recipient.address_postcode}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Country of Domicile: </Text>
                        <Text>{transfer_details.recipient.address_country_desc}</Text>
                      </RowWrapper>
                    </>
                    <LineBreak />
                    <>
                      <Title>Recipient VASP</Title>
                      <RowWrapper>
                        <Text>VASP Name: </Text>
                        <Text>{transfer_details.recipient_vasp.vasp_name_business}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Legal Name: </Text>
                        <Text>{transfer_details.recipient_vasp.vasp_name_legal}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Website: </Text>
                        <Text>{transfer_details.recipient_vasp.vasp_website}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Email: </Text>
                        <Text>{transfer_details.recipient_vasp.vasp_email}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Country of Domicile: </Text>
                        <Text>{transfer_details.recipient_vasp.address_country_desc}</Text>
                      </RowWrapper>
                    </>
                  </ColWrapper>
                </RowWrapper>

                <RowWrapper>
                  <ColWrapper>
                    <Title>Transaction Description</Title>
                    {transfer_status && (
                      <>
                        <RowWrapper>
                          <Text>Originator Hash Address: </Text>
                          <Text>{transfer_status.originator_hash_address}</Text>
                        </RowWrapper>
                        <RowWrapper>
                          <Text>Recipient Hash Address: </Text>
                          <Text>{transfer_status.recipient_hash_address}</Text>
                        </RowWrapper>
                        <RowWrapper>
                          <Text>Amount: </Text>
                          <Text>{transfer_status.amount}</Text>
                        </RowWrapper>
                        <RowWrapper>
                          <Text>Asset: </Text>
                          <Text>{transfer_status.asset_desc}</Text>
                        </RowWrapper>
                        <RowWrapper>
                          <Text>Date: </Text>
                          <Text>{transfer_status.created}</Text>
                        </RowWrapper>
                      </>
                    )}
                  </ColWrapper>
                </RowWrapper>
              </TransferDetailsWrapper>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
TravelRuleTableItemDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  transfer_status: [],
}

// Proptypes Validation
TravelRuleTableItemDetails.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  table_data: PropTypes.shape({
    direction_id: PropTypes.number,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedRecipient: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  transfer_status: PropTypes.arrayOf({
    transfer_number: PropTypes.string,
    transfer_author: PropTypes.string,
    transfer_steps: PropTypes.shape({}),
  }),
  transfer_details: PropTypes.any,
  isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableItemDetails)
