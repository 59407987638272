export const BASE_URL = 'https://api.ospree.io'
export const DEMO_URL = 'https://flowapi-demo.ospree.io'
export const OLD_URL = 'https://apitest2.nodestash.com'
export const LOCAL_URL = 'http://localhost:8000'
//! REVERT BACK TO NEW URL BEFORE DEPLOYING TO PROD
export const AUTH_ENDPOINTS = {
  GET_USER: '/api/ui/users/',
  UPDATE_USER: '/api/ui/users/',
  LOGIN: '/api/ui/users/login',
  LOGOUT: '/api/ui/users/logout',
  RESET_PASSWORD: '/api/ui/users/reset-password',
  FORGOT_PASSWORD: '/api/ui/users/forgot-password',
  REGISTER: '/api/ui/users/register',
}

// Notes endpoints
export const NOTE_ENDPOINTS = {
  CREATE_NOTE: '/ui/notes',
  GET_NOTE: '/ui/notes',
}

// Address endpoints
export const ADDRESS_ENDPOINTS = {
  CREATE_OR_UPDATE_ADDRESS: '/api/ui/analytics/address',
  LINK_ADDRESS: '/api/ui/analytics/addresses',
  DELETE_ADDRESS: '/api/ui/analytics/addresses',
  GET_ADDRESSES: '/api/ui/analytics/addresses',
  GET_ADDRESS_DETAILS: '/api/ui/analytics/addresses',
  GET_ADDRESS_HISTORY: '/api/ui/analytics/addresses',
  POST_ADDRESS_REPORT: '/api/ui/analytics/addresses',
}

// Reports endpoints
export const REPORT_ENDPOINTS = {
  GET_REPORTS: '/api/ui/reports',
  GET_REPORT: '/api/ui/reports',
}

// Directory endpoints
export const ACCOUNT_ENDPOINTS = {
  GET_ALL_ACCOUNTS: '/api/ui/directory/accounts',
  GET_ACCOUNT: '/api/ui/directory/accounts/',
  SEARCH_ACCOUNT: '/api/ui/directory/accounts/search',
  CREATE_ACCOUNT_ENTITY: '/api/ui/directory/accounts/entity',
  CREATE_ACCOUNT_INDIVIDUAL: '/api/ui/directory/accounts/individual',
  UPDATE_ACCOUNT_ENTITY: '/api/ui/directory/accounts/entity/',
  UPDATE_ACCOUNT_INDIVIDUAL: '/api/ui/directory/accounts/individual/',
}
export const RECIPIENT_ENDPOINTS = {
  GET_ALL_RECIPIENTS: '/api/ui/directory/recipients',
  GET_RECIPIENT: '/api/ui/directory/recipients/',
  SEARCH_RECIPIENT: '/api/ui/directory/recipients/search',
  CREATE_RECIPIENT_ENTITY: '/api/ui/directory/recipients/entity',
  CREATE_RECIPIENT_INDIVIDUAL: '/api/ui/directory/recipients/individual',
  UPDATE_RECIPIENT_ENTITY: '/api/ui/directory/recipients/entity/',
  UPDATE_RECIPIENT_INDIVIDUAL: '/api/ui/directory/recipients/individual/',
}
export const VASP_ENDPOINTS = {
  GET_ALL_VASPS: '/api/ui/directory/vasps',
  GET_VASP: '/api/ui/directory/vasps/',
  SEARCH_VASP: '/api/ui/directory/vasps/search',
  CREATE_VASP: '/api/ui/directory/vasps',
  UPDATE_VASP: '/api/ui/directory/vasps',
}
// Travel Rule endpoints
export const TRAVEL_RULE_ENDPOINTS = {
  GET_ASSET_CONVERSION: '/api/ui/travelrule/conversion',
  GET_ALL_TRANSFERS: '/api/ui/travelrule/transfers',
  GET_TRANSFER: '/api/ui/travelrule/transfers/',
  CREATE_TRANSFER: '/api/ui/travelrule/transfers/start',
  UPDATE_TRANSFER: '/api/ui/travelrule/transfers/',
  SEARCH_TRANSFER: '/api/ui/travelrule/transfers/search',
  GET_RECIPIENT_VASP: '/api/ui/travelrule/transfers/analyzer',
}

export const WHITELISTING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/whitelist/paged-table',
  RISK_BUCKET: '/api/whitelist/risk-bucket',
  ADDRESS_HISTORY: '/api/whitelist/address-hist',
  ADDRESS_COUNT: '/api/whitelist/count-address',
  ASSIGN_ADDRESS: '/api/whitelist/assign-address',
  SEARCH_ADDRESS: '/api/whitelist/search-address?',
  REMOVE_ADDRESS: '/api/whitelist/remove-address',
  GET_ADDRESS_REPORT: '/api/reporting/address',
  UPDATE_ADDRESS_NOTES: '/api/whitelist/notes',
}

export const MONITORING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/monitoring/paged-table',
  RISK_BUCKET: '/api/monitoring/risk-bucket',
  TRANSACTION_HISTORY: '/api/monitoring/transaction-hist',
  TRANSACTION_COUNT: '/api/monitoring/count-transaction',
  ASSIGN_TRANSACTION: '/api/monitoring/assign-transaction',
  SEARCH_TRANSACTION: '/api/monitoring/search-transaction?',
  REMOVE_TRANSACTION: '/api/monitoring/remove-transaction',
  GET_TRANSACTION_REPORT: '/api/reporting/transaction',
  UPDATE_TRANSACTION_NOTES: '/api/monitoring/notes',
}

export const CASES_ENDPOINTS = {
  // Dashboard
  DASHBOARD_TOTAL_CASES: '/api/case/dashbaord-case-total',
  DASHBOARD_CASE_ADDRESSES: '/api/case/dashbaord-case-addresses',
  DASHBOARD_CASE_TRANSACTIONS: '/api/case/dashbaord-case-transactions',

  // Create Case
  CREATE_CASE: '/api/case/create-case',

  // Read
  READ_ALL_CASES: '/api/case/read-all-case',
  READ_CASE_ACTIVITIES: '/api/case/read-case-activities',
  READ_CASE_CUSTOMER: '/api/case/read-case-customer',
  READ_CASE_ADDRESSES: '/api/case/read-address-case',
  READ_CASE_DETAILS: '/api/case/read-detail-case',
  READ_CASE_TRANSACTIONS: '/api/case/read-transaction-case',
  READ_CASE_ALERTS: '/api/alerts/get_alerts/customer',

  // Search
  AUTOCOMPLETE_CUSTOMER_SEARCH: '/api/case/search-customer',
  CUSTOMER_SEARCH: '/api/case/read-case-customer',

  UPDATE_CASE: '/api/case/update-case',
  GET_ALL_COUNTRIES: '/api/case/get-all-countries',
  INVESTIGATION: '/api/investigation/investigation',
}

export const ALERTS_ENDPOINTS = {
  // Read
  READ_ALL_ALERTS: '/api/alerts/get_alerts',
  READ_ALERT_DETAILS: '/api/alerts/get_alert_details',
  READ_DAILY_ALERTS: '/api/alerts/get_daily_alerts',
  READ_ALERTS_BY_SEVERITY: '/api/alerts/get_alerts_by_severity',
  READ_ALERTS_BY_STATUS: '/api/alerts/get_alerts_by_status',
}

export const PROFILE_ENDPOINTS = {
  READ_PROFILE: '/api/ui/users',
  UPDATE_PROFILE: '/api/ui/users',
}

export const EXTERNAL_URLS = {
  MAIN_SITE: 'https://www.ospree.io/',
  GET_STARTED: 'https://www.ospree.io/get-demo',
  FEEDBACK: 'https://ospree.io/help',
  PRIVACY_POLICY: 'https://ospree.io/terms-and-conditions',
  TERMS_OF_SERVICE: 'https://ospree.io/terms-and-conditions',
  OSPREE_VERSION_2: 'https://ospree.io/blog/ospree-version-2/',
}

export const BATCH_UPLOAD_UPLOADS = {
  CASES: '/api/case/batch-create-cases',
  ADDRESSES: '/api/case/batch-address',
  TRANSACTIONS: '/api/case/batch-transaction',
}
