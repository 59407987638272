// Route Components
import {
  Login,
  ResetPassword,
  NotFound,
  UserDetails,
  Settings,
  Cases,
  CaseDetails,
  Policies,
  PolicyDetails,
  Accounts,
  AccountDetails,
  Recipients,
  RecipientDetails,
  Vasps,
  VaspDetails,
  TravelRule,
  Address,
  Transactions,
  Investigation,
  Reports,
} from 'views/pages'

export default [
  // Home
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: TravelRule,
      path: '/travelrule',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Cases,
      path: '/cases',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Policies,
      path: '/cases/policies',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: PolicyDetails,
      path: '/cases/policies/:policyId',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: CaseDetails,
      path: '/cases/details/:caseId',
    },
  },
  // Directory
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Accounts,
      path: '/directory',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Recipients,
      path: '/directory/recipients',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Vasps,
      path: '/directory/vasps',
    },
  },
  // Directory Details
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: AccountDetails,
      path: '/directory/accounts/details/:account_id',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: RecipientDetails,
      path: '/directory/recipients/details/:recipient_id',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: VaspDetails,
      path: '/directory/vasps/details/:vasp_id',
    },
  },

  // Blockchain Analytics
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Address,
      path: '/',
    },
  },
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Transactions,
      path: '/blockchain-analytics/transactions',
    },
  },
  // Reports
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Reports,
      path: '/reports',
    },
  },
  // Travel Rule
  // {
  //   exact: true,
  //   isRouteProtected: false,
  //   routing: {
  //     component: TravelRule,
  //     path: '/travelrule',
  //   },
  // },
  // User Profile
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: UserDetails,
      path: '/user-details/:user_id',
    },
  },
  // Settings
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Settings,
      path: '/settings',
    },
  },
  // Investigation
  {
    exact: true,
    // ! Change to protected
    isRouteProtected: true,
    routing: {
      component: Investigation,
      path: '/investigation/:hash',
    },
  },
  // Login
  { isRouteProtected: false, exact: true, routing: { component: Login, path: '/login' } },
  // Reset Password
  { isRouteProtected: false, exact: true, routing: { component: ResetPassword, path: '/reset-password' } },
  { isRouteProtected: false, exact: false, routing: { component: NotFound } },
]
