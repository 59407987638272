/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableWrapper, TableBodyOverflowWrapper, TableBodyWrapper, TableBodyChildrenWrapper } from './Table.elements'

// Views
import { Pagination } from 'views/components'

// Map Redux Props
const mapStateToProps = ({ filters }) => ({ filters })
const mapDispatchToProps = actions
function Table(props) {
  // Destructure
  const { totalItems, filterComponents, tableFilters, setTableFilters, children, minWidth } = props

  // States
  const { size, page } = tableFilters
  // Functions
  const handlePageChange = async ({ page, size }) => {
    await setTableFilters({ ...tableFilters, page, size })
  }
  return (
    <>
      <TableWrapper allowZeroExpanded>
        {/* {headerOptions && (
          <TableHeaderWrapper>
            <Button
              variant="outlined"
              onClick={handleCollapseFilter}
              startIcon={<Image src={FilterIcon} width={15} height={15} />}
            >
              Filters
            </Button>
            <HeaderFilterButton>
              <HeaderOptionsWrapper>{headerOptions}</HeaderOptionsWrapper>
            </HeaderFilterButton>
          </TableHeaderWrapper>
        )} */}

        {filterComponents && filterComponents}

        <TableBodyOverflowWrapper>
          <TableBodyWrapper minWidth={minWidth}>
            {/* <TableBodyHeaderWrapper gap={gap} headerPadding={headerPadding} showHeader={withHeader}>
              {' '}
              {headers.map(({ name, subHeader, sort, identifier, width }) => (
                <TableBodyHeaderItem
                  key={name}
                  sortable={sort}
                  width={width}
                  onClick={() => sort && handleSort({ identifier })}
                >
                  <TableBodyHeaderText>
                    {name}
                    {subHeader && <TableBodySubHeaderText> {subHeader} </TableBodySubHeaderText>}
                  </TableBodyHeaderText>
                  {sort && (
                    <TableBodyHeaderIcon sortDirection={sortDirection} active={isHeaderSortActive({ identifier })}>
                      <Image src={ArrowDownIcon} width={18} height={18} />
                    </TableBodyHeaderIcon>
                  )}
                </TableBodyHeaderItem>
              ))}
            </TableBodyHeaderWrapper> */}

            <TableBodyChildrenWrapper>{children}</TableBodyChildrenWrapper>
          </TableBodyWrapper>
        </TableBodyOverflowWrapper>
      </TableWrapper>
      <Pagination
        itemsCountPerPage={size}
        totalItemsCount={totalItems}
        page={page}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
Table.defaultProps = {
  totalItems: 0,
  filterComponents: '',
  filters: {},
  actions: {},
}

// Proptypes Validation
Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  tableFilters: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    page: PropTypes.number,
    size: PropTypes.number,
  }),
  setTableFilters: PropTypes.func,
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDirection: PropTypes.string,
      page: PropTypes.number,
      size: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setCaseManagementFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
