import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

// Route Configurations
import Routes from 'common/config/route'

// Global Components
import GlobalComponents from 'views/global'

function Routing() {
  /// Check if user is logged in
  const userCredentials = JSON.parse(localStorage.getItem('userCredentials'))
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))

  // Checking if Access token has expired
  if (userCredentials) {
    if (Object.keys(userCredentials).length !== 0) {
      const expiryDate = new Date(userCredentials.expiry).getTime()
      const now = new Date().getTime()
      if (now > expiryDate) {
        localStorage.removeItem('userCredentials')
        return window.location.reload()
      }
    }
  }

  /**
   * Validates if current authenticated user is authorized to view a route
   * @param  {} roles
   */
  const isUserAuthorized = (roles) => {
    // If route has role restriction, check if user role is authorized
    if (roles && roles.length) roles.some((role) => role === userCredentials.role)

    // Else route has no restriction give user access
    return true
  }

  /**
   * Conditionally selects route/component to render based on authentication or authorization
   * @param  {} isRouteProtected
   * @param  {} routeRoleAccess
   * @param  {} component
   */
  const renderRouteComponent = (isRouteProtected, routeRoleAccess, component, path) => {
    // Render not found route
    if (isRouteProtected === undefined || !path) {
      return <>{React.createElement(component)}</>
    }

    if (!isRouteProtected) {
      // Render public routes
      if (!isLoggedIn || !userCredentials?.access_token) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
      // Redirect to home page if logged in user access public routes
      if (isLoggedIn && userCredentials?.access_token) {
        return (
          <>
            <Redirect to="/" />
            <GlobalComponents />
          </>
        )
      }
    }

    if (isRouteProtected) {
      // Redirect to login page if credentials are missing or not logged in
      if (!userCredentials?.access_token || !isLoggedIn) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      // Render protected routes
      if (userCredentials?.access_token && isUserAuthorized(routeRoleAccess) && isLoggedIn) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        {Routes.map(({ isRouteProtected, exact, roleAccess, routing: { path, component } }) => (
          <Route
            exact={exact}
            path={path}
            key={uuid()}
            render={() => renderRouteComponent(isRouteProtected, roleAccess, component, path)}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}

export default Routing
