import React from 'react'

export default function ChangeIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1L9 2.71429L8 4.42857"
        stroke="#727F97"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 5.00056V4.23865C4 3.83451 4.11706 3.44693 4.32544 3.16116C4.53381 2.87539 4.81643 2.71484 5.11111 2.71484H9"
        stroke="#727F97"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.99986L1 7.28557L2 5.57129"
        stroke="#727F97"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5V5.7619C6 6.16604 5.88294 6.55363 5.67456 6.8394C5.46619 7.12517 5.18357 7.28571 4.88889 7.28571H1"
        stroke="#727F97"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
