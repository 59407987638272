import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
import {
  // ADDRESS_REGEX_OPTIONS, ADDRESS_CHAIN_OPTIONS_MAPPING,
  ADDRESS_CHAIN_OPTIONS,
} from 'common/constants/formOptions'

// Hooks
import { useCreateOrUpdateAddress } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  Backdrop,
  // Title,
  CloseIconWrapper,
  ChainChoiceWrapper,
  ChainChoice,
} from './AddressSearchBar.elements'

// Views
import { TextField } from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressSearchBar(props) {
  // props
  const { actions } = props

  // destructure
  const { showAlert, setScannedAddress } = actions

  // local states
  const [isActive, setIsActive] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const [chain, setChain] = useState('')
  // const [matchedChains, setMatchedChains] = useState([])
  // const [otherChains, setOtherChains] = useState([])

  // hooks
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    createOrUpdateAddress({
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
    })
    setAddressInput('')
    setChain('')
  }
  // function handleChainMatch(inputValue) {
  //   const currentMatchedChains = []
  //   const currentOtherChains = []
  //   const currentOtherChainsMapping = ADDRESS_CHAIN_OPTIONS_MAPPING
  //   const matchedChains = {}
  //   ADDRESS_REGEX_OPTIONS.forEach((item) => {
  //     console.log(item)
  //     const re = new RegExp(item.regex)
  //     console.log(re.test(inputValue))
  //     if (re.test(inputValue) === true) {
  //       currentMatchedChains.push(ADDRESS_CHAIN_OPTIONS_MAPPING[item.chain.split('-').join('_')])
  //       matchedChains[item.chain.split('-').join('_')] = true
  //     }
  //   })
  //   Object.keys(currentOtherChainsMapping).forEach((key) => {
  //     if (matchedChains[key] !== true) {
  //       currentOtherChains.push(currentOtherChainsMapping[key])
  //     }
  //   })
  //   setMatchedChains(currentMatchedChains)
  //   setOtherChains(currentOtherChains)
  // }
  function handleAddressChange(inputValue) {
    setAddressInput(inputValue)
    // handleChainMatch(inputValue)
  }

  useEffect(() => chain && handleSubmit(), [chain])
  useEffect(() => !isActive && setAddressInput(''), [isActive])
  useEffect(() => {
    if (createOrUpdateAddressData) {
      setScannedAddress(createOrUpdateAddressData)
      setIsActive(false)
    }
  }, [createOrUpdateAddressData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateAddressError) showAlert({ type: 'error', message: 'An error occured in screening address' })
  }, [createOrUpdateAddressError])

  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={
                isCreateOrUpdateAddressLoading ? 'loading...' : !isActive && 'Screen a crypto wallet address ...'
              }
              onClick={() => {
                setIsActive(true)
              }}
              value={addressInput}
              onChange={(e) => handleAddressChange(e.currentTarget.value)}
            />
          </SearchInputWrapper>
          {addressInput && isActive && !isCreateOrUpdateAddressLoading && (
            <ChainChoiceWrapper isActive={isActive}>
              {/* <Title>Select Chain: </Title> */}
              {ADDRESS_CHAIN_OPTIONS &&
                ADDRESS_CHAIN_OPTIONS.map((item) => (
                  <ChainChoice
                    key={item.value}
                    style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => !item.disabled && setChain(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </ChainChoice>
                ))}
              {/* <Title>Other Chain Options: </Title>
          {otherChains &&
            otherChains.map((item) => (
              <ChainChoice
                key={item.value}
                style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                onClick={() => !item.disabled && setChain(item.value)}
              >
                {item.label}
              </ChainChoice>
            ))} */}
            </ChainChoiceWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
AddressSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
AddressSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedAddress: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearchBar)
